<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleTokenDialog">
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("hub_token") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <span align="center" v-if="jwt_token">{{
                        $t("add_this_tokrn_to_express_dash_to_connecte_ds")
                      }}</span>
                      <hr v-if="jwt_token" class="my-5" />
                      {{ jwt_token }}
                    </v-col>
                  </v-row>
                  <hr v-if="jwt_token" class="my-5" />
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        dense
                        outlined
                        :label="$t('update_express_jwt_token')"
                        v-model="hub.external_jwt"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              class="justify-center"
              text
              @click="generateJWT(hub)"
              :disabled="!is_valid"
            >
              {{ $t("generate_token") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="updateExternalJWT(hub)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    isVisibleTokenDialog: {
      get() {
        return this.$store.state.hubs.isVisibleTokenDialog;
      },
      set(value) {
        this.$store.commit("hubs/IS_VISIBLE_TOKEN_DIALOG", value);
      },
    },

    hub: {
      get() {
        return this.$store.state.hubs.editedHub;
      },
      set(value) {
        this.$store.commit("hubs/EDITED_HUB", value);
      },
    },
  },

  data() {
    return {
      is_valid: true,
      jwt_token: null,
    };
  },

  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.$store.dispatch("hubs/closeForm");
    },

    async updateExternalJWT(hub) {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch("hubs/updateExternalJWT", hub);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
    async generateJWT(hub) {
      try {
        await this.$store.dispatch("hubs/generateJWT", hub).then((token) => {
          this.jwt_token = token;
          navigator.clipboard?.writeText(token);
        });
        this.$store.dispatch("alerts/success", this.$t("token_copied"));
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
